<template>
  <div class="container-fluid">
    <Header header="Laporan" title="Dokument Masuk" :state="state"/>

    <div class="row" v-if="!loaded">
      <div
        class="col-12 col-sm-4"
        v-for="(value, index) in Array.from(Array(6).keys())"
        :key="index"
      >
        <div class="ph-item">
          <div class="ph-col-12">
            <div class="ph-picture"></div>
            <div class="ph-row">
              <div class="ph-col-4"></div>
              <div class="ph-col-8 empty"></div>
              <div class="ph-col-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view v-else/>
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/pageTitle/index";

export default {
  components: {
    Header
  },
  data() {
    return {
      contacts: [],
      loaded: true,
      state: {
        isEdit: false,
        isAdd: false,
        isDetail: false
      }
    };
  },

  async mounted() {
    // this.get(state);
  },
  methods: {
    async get() {
      try {
        const response = await axios.get("/contacts");
        this.contacts = response.data;
        this.loaded = true;
      } catch (e) {
        this.handleApiError(e);
      }
    },
    async remove(id) {
      try {
        if (confirm("Are you sure?")) {
          await axios.delete(`/contacts/${id}`);
          this.get(state);
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    goToAddView() {
      this.state = {
        isAdd: true,
        isEdit: false,
        isDetail: false
      };
      this.$router.push("/laporanDokumentMasuk/add");
    }
  },
  watch: {
    $route() {
      if (this.$route.params.state) {
        this.state = this.$route.params.state;
      }
    }
  }
};
</script>
